@import 'mixins';
@import 'fonts';
@import '../utils/font-awesome';

// This fixes author device emulator background overwrite problem.
#cq-emulator-content.firefox {
  background-color: #fff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 15px 0;
}

a {
  color: #000;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

input[type="search"] {
  box-sizing: content-box;
}

.component {

  &-content {
    @include clearfix;
  }

}

.clearfix {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }
}

.pull {
  &-right {
    float: right;
  }

  &-left {
    float: left;
  }
}

.selection-bold {
  font-weight: bold;
}

/* forms */

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* spacing */

.no-space {
  margin: 0;
  padding: 0;
}

.no-margin, .no-margin > div {
  margin: 0;
}

.no-padding, .no-padding > div {
  padding: 0;
}

.space-inside-top {
  padding-top: 30px;
}

.space-inside-left {
  padding-left: 30px;
}

.space-inside-right {
  padding-right: 30px;
}

.space-inside-bottom {
  padding-bottom: 30px;
}

/* alignments */

.middle {
  margin: 0 auto;
  text-align: center;
}

.right {
  margin: 0 0 0 auto;
  text-align: right;
}

.vertically-middle .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vertically-middle .content > * {
  display: inline-block;
}

/* borders */

.border-top {
  border-top: 1px solid #e4e4e4;
}

.border-left {
  border-left: 1px solid #e4e4e4;
}

.border-right {
  border-right: 1px solid #e4e4e4;
}

.border-bottom {
  border-bottom: 1px solid #e4e4e4;
}

/* misc */

.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

.paragraphSystem, .row {
  margin: 0;
}

/* main */

html, body {
  width: 100%;
  font-size: 1rem;
  background: #9E0B0E;
}

body {
  position: relative;
  overflow: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.container {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

body > .container > .row {
  position: relative;
}

/* layout: header */

div#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  width: 100%;
  max-width: 100%;
  padding: 0;
  border-bottom: 1px solid #dee2e6;
  overflow: visible;
  background: #fff;
  z-index: 9999;
}

div#header div[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.reference-header-public > .inner,
.reference-header-hcp > .inner {
  margin: 0 auto;
  max-width: 1140px;
}

.reference-header-public > .inner > .component-content,
.reference-header-hcp > .inner > .component-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.reference-header-public .header-sidebar > div,
.reference-header-hcp .header-sidebar > div {
  margin-left: auto;
}

.reference-header-public .image,
.reference-header-hcp .image {
  padding-top: 0;
  padding-bottom: 0;
}

.reference-header-public .richText,
.reference-header-hcp .richText {
  padding-top: 0;
  padding-bottom: 0;
}

.reference-header-public .header-logo > div,
.reference-header-public .header-logo-secondary > div,
.reference-header-hcp .header-logo > div,
.reference-header-hcp .header-logo-secondary > div {
  text-align: center;
}

.reference-header-public .header-logo a,
.reference-header-public .header-logo-secondary > div,
.reference-header-hcp .header-logo a,
.reference-header-hcp .header-logo-secondary > div {
  display: inline-block;
  height: $header-height;
  line-height: 62px;
  padding: 0 20px;
}

.reference-header-public .header-logo img,
.reference-header-public .header-logo-secondary img,
.reference-header-hcp .header-logo img,
.reference-header-hcp .header-logo-secondary img {
  display: inline-block;
  vertical-align: middle;
}

.reference-header-public .header-logo img,
.reference-header-hcp .header-logo img {
  height: 48px;
  max-height: 48px;
}

.reference-header-public .header-logo-secondary img,
.reference-header-hcp .header-logo-secondary img {
  height: 34px;
  max-height: 34px;
}

.reference-header-public .box-heading,
.reference-header-hcp .box-heading {
  display: none;
}

/* layout: content */

div#content {
  margin-top: $header-height;
  padding: 0;
  background: #fff;
}

div#content .layout-inner > div.col-xs-12, div#content .layout-inner > div.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

#content h1,
#content h2,
#content h3,
#content h4,
#content h5,
#content h6 {
  font-family: 'Open Sans', sans-serif;
}

#content h1 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  color: $darkred;
}

#content h2 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: #000;
}

#content h3 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: $darkred;
}

#content h4 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: $darkred;
}

#content h6 {
  margin: 0;
  padding: 15px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: $darkred;
}

/* bg styles */

.bg-transparent > div {
  background-color: rgba(255,255,255,.8);
}

.bg-light-gray {
  background-color: $lightgray;
}

.bg-dark-gray {
  background-color: $darkgray;
}

.bg-dark-red {
  background-color: $darkred;
}

/* formatting */

.superscript {
  font-size: 70%;
  vertical-align: super;
}

/* divider */

.divider {
  display: block;
  width: 100%;
  padding: 15px 15px 0 15px;
  clear: both;
}

.divider > div {
  display: block;
  width: 100%;
  height: 15px;
  border-top: 1px solid #e5e5e5;
}

.divider.clearer > div {
  height: 0.01rem;
  clear: both;
  border: none;
}

/* approval code */

.approval-code {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #ccc;
}

/* icons */

.lock-icon:before {
  @include font-awesome($fa-var-lock);
  padding-right: 10px;
}

.calendar-info:before {
  @include font-awesome($fa-var-calendar);
  padding-right: 10px;
}

.time-info:before {
  @include font-awesome($fa-var-clock-o);
  padding-right: 10px;
}

.icon-pdf:before {
  @include font-awesome($fa-var-file-pdf-o);
  padding-right: 10px;
}

.icon-download:before {
  @include font-awesome($fa-var-download);
  padding-right: 10px;
}

.icon-back:before {
  @include font-awesome($fa-var-chevron-left);
  padding-right: 10px;
}

/* buttons */

.red-button, #content .richText .red-button {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6rem;
  background: $darkred;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.2s linear 0s;
}

.red-button:before, .richText .red-button:before {
  content: "";
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  width: 30px;
  border-radius: 0 50% 50% 0;
  transform: scale(0, 1);
  transform-origin: left center;
  transition: all 0.2s linear 0s;
}

.red-button:hover, .richText .red-button:hover {
  text-indent: 30px;
  background-color: $yellow !important;
}

.red-button:hover:before, .richText .red-button:hover:before {
  transform: scale(1, 1);
  text-indent: 0;
}

.yellow-button, #content .richText .yellow-button {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6rem;
  background: $yellow;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.2s linear 0s;
}

.yellow-button:before, .richText .yellow-button:before {
  content: "";
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  width: 30px;
  border-radius: 0 50% 50% 0;
  transform: scale(0, 1);
  transform-origin: left center;
  transition: all 0.2s linear 0s;
}

.yellow-button:hover, .richText .yellow-button:hover {
  text-indent: 30px;
  background-color: #A18043 !important;
}

.yellow-button:hover:before, .richText .yellow-button:hover:before {
  transform: scale(1, 1);
  text-indent: 0;
}

.white-button, #content .richText .white-button {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6rem;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.2s linear 0s;
  border: 1px solid #fff;
}

.white-button:before, .richText .white-button:before {
  content: "";
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  width: 30px;
  border-radius: 0 50% 50% 0;
  transform: scale(0, 1);
  transform-origin: left center;
  transition: all 0.2s linear 0s;
}

.white-button:hover, .richText .white-button:hover {
  text-indent: 30px;
  background-color: rgba(255,255,255,.35) !important;
}

.white-button:hover:before, .richText .white-button:hover:before {
  transform: scale(1, 1);
  text-indent: 0;
}

/* layout: content / top bar box */

.top-bar-box > div {
  padding: 60px 20px 20px 20px;
}

.top-bar-box > div:before {
  position: absolute;
  display: block;
  top: 0;
  left: 50px;
  right: 50px;
  content: " ";
  height: 39px;
  background-image: url(../../img/flag-header.svg);
}

#content .top-bar-box h1,
#content .top-bar-box h2,
#content .top-bar-box h3 {
  font-weight: 400;
}

/* layout: content / red hover area */

.red-hover-area > div {
  margin: 15px 0;
  padding: 15px;
  background-color: $darkred;
  color: #fff;
}

.red-hover-area > div:hover {
  background-color: $yellow;
}

#content .red-hover-area.richText a {
  color: #fff;
}

#content .red-hover-area.richText ul {
  margin: 0;
  padding: 0;
  color: #fff;
}

#content .red-hover-area.richText ul li {
  margin: 5px 0;
  color: #fff;
}

#content .red-hover-area.richText ul li:before {
  color: #fff;
}

/* layout: content / title */

#content .title {
  margin: 0;
  padding: 0 20px;
}

#content .title:first-of-type {
  padding-top: 20px;
}

/* layout: content / image */

.image {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.image figcaption {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.image a {
  display: block;
  position: relative;
}

.image img {
  max-width: 100%;
}

/* layout: content / richText */

#content .richText p {
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 0;
  padding: 10px 0;
}

#content .richText a {
  font-size: 1rem;
  line-height: 1.6rem;
  color: $darkred;
}

#content .richText ul {
  margin: 15px 0 15px 30px;
  padding: 0;
  list-style-type: none;
}

#content .richText ul li {
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 30px;
  font-size: 1rem;
  line-height: 1.6rem;
}

#content .richText ul li:before {
  @include font-awesome($fa-var-circle-o-notch);
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
}

#content .richText ol {
  margin: 15px 0 15px 30px;
  padding: 0;
}

#content .richText ol li {
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 15px;
  font-size: 1rem;
  line-height: 1.6rem;
}

.white-content {
  color: #fff;
}

.white-content a {
  color: #fff;
}

/* layout: content / table */

#content .table .table-heading {
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.7rem;
}

#content .table {
  overflow-x: scroll;
}

#content .table table {
  width: 100%;
  min-width: 600px;
  margin: 15px 0;
  border-collapse: separate;
  border-spacing: 0;
}

#content .table table tbody {
  margin: 0;
  padding: 0;
}

#content .table table tr {
  background: #fff;
}

#content .table table tr:nth-child(odd) {
  background: #f6f6f6;
}

#content .table table tr:nth-child(odd) td {
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

#content .table table tr:first-child {
  background: #fff;
}

#content .table table td {
  margin: 0;
  padding: 10px 15px;
  vertical-align: top;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8rem;
}

#content .table table tr:first-child td {
  font-size: 1rem;
  font-weight: 700;
  color: #333;
  border-top: none;
  border-bottom: 1px solid #464646;
}

/* layout: content / legal note */

#content .legal-note {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #888;
}

/* layout: content / disclaimer */

#content .disclaimer {
  color: #333;
}

#content .disclaimer p, #content .disclaimer ul, #content .disclaimer ul li, #content .disclaimer ol, #content .disclaimer ol li {
  font-size: 0.9rem;
  line-height: 1.3rem;
}

/* layout: content / article compact */

#content .article-compact {
  clear: both;
  float: none;
  width: 100%;
}

#content .article-compact > div {
  float: none;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 1140px;
}

/* layout: content / block: video asset */

.video-asset {
  margin: 20px 0;
  padding: 0;
  background: #fff;
}

.video-asset-with-link .video-asset-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.video-asset-with-link .video-asset-content-body {
  width: 70%;
}

.video-asset-with-link .video-asset-content-link {
  width: 30%;
}

.video-asset-content {
  padding: 10px 0 10px 15px;
}

.video-asset-content-body {
  min-height: 110px;
  padding: 0 10px 0 0;
  overflow: hidden;
}

.video-asset-with-link .video-asset-content-body {
  border-right: 1px solid #c6c6c6;
}

.video-asset-content-body .video-asset-title p {
  margin: 5px 0;
  padding: 0;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.5rem;

}

.video-asset-content-body .video-asset-description p {
  margin: 5px 0;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
}

.video-asset-content-body .video-asset-approval-code {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #ccc;
}

.video-asset-content-link {
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}

/* layout: content / search box */

.search-results .searchBox form {
  position: relative;
  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  height: $header-height;
}

.search-results .searchBox .searchBox-title {
  display: none;
}

.search-results .searchBox form label {
  display: block;
  font-weight: 400;
}

.search-results .searchBox form input {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 4px 40px 4px 3px;
  line-height: 2rem;
  border: 1px solid #ddd;
  background: #fff;
  font-size: 1rem;
  text-indent: 12px;
}

.search-results .searchBox form button {
  position: absolute;
  top: 13px;
  right: 0;
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
}

.search-results .searchBox form button {
  font-size: 0;
  color: transparent;
  cursor: pointer;
}

.search-results .searchBox form button:before {
  position: absolute;
  font-family: 'FontAwesome';
  content: "\f002";
  top: 6px;
  left: 10px;
  font-weight: 900;
  font-size: 1.2rem;
  display: inline-block;
  color: #000;
}

/* layout: search results / search box */

.search-results form {
  position: relative;
  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  height: $header-height;
}

.search-results .searchBox-title {
  display: none;
}

.search-results .searchBox form input {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 4px 40px 4px 3px;
  line-height: 2rem;
  border: 1px solid #ddd;
  background: #fff;
  font-size: 1rem;
  text-indent: 12px;
}

.search-results .searchBox form button {
  position: absolute;
  top: 13px;
  right: 0;
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
}

.search-results .searchBox form button,
.search-results .searchBox form button:hover,
#content .search-results .button {
  padding: 13px 15px;
  font-size: 0;
  color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;
}

.search-results .searchBox form button:before {
  position: absolute;
  font-family: 'FontAwesome';
  content: "\f002";
  top: 8px;
  left: 10px;
  font-weight: 900;
  font-size: 1.2rem;
  display: inline-block;
  color: #000;
}

/* layout: footer */

div#footer {
  margin: 15px 0 0 0;
  padding: 20px 0 0 0;
}

.reference-footer > .inner {
  margin: 0 auto;
  max-width: 1140px;
}

.reference-footer .component-content {
  text-align: center;
}

.reference-footer .image {
  margin: 0;
  padding: 10px;
}

.reference-footer .image img {
  width: 50px;
}

.reference-footer .richText ul {
  display: block;
  margin: 0;
  padding: 10px 0;
  list-style-type: none;
}

.reference-footer .richText ul li {
  display: block;
  margin: 0;
  padding: 10px 0;
}

.reference-footer .richText ul li a {
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
}

.reference-footer .richText ul li a:hover {
  text-decoration: underline;
}

.reference-footer .richText p {
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
}

.reference-footer .richText .small-light-gray {
  font-size: 0.85rem;
  font-weight: 300;
  color: #fff;
}

.reference-footer .contact {
  padding-top: 15px;
  padding-bottom: 15px;
}

.reference-footer .contact > div {
  border: 1px solid rgba(255,255,255,.35);
  padding: 20px;
}

.mail-button, .phone-button {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6rem;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.2s linear 0s;
  border: 1px solid #fff;
}

.mail-button:before {
  @include font-awesome($fa-var-envelope-o);
  padding-right: 15px;
}

.phone-button:before {
  @include font-awesome($fa-var-phone);
  padding-right: 15px;
}

.mail-button:hover, .phone-button:hover {
  color: #fff;
  background-color: rgba(255,255,255,.35) !important;
}

/* formatting */

.superscript {
  font-size: 70%;
  vertical-align: super;
}

/* divider */

.divider {
  display: block;
  width: 100%;
  padding: 20px 0 0 0;
}

.divider > div {
  display: block;
  width: 100%;
  height: 20px;
  padding: 0;
  border-top: 1px solid #e5e5e5;
}

/* icons */

.lock-icon:before {
  @include font-awesome($fa-var-lock);
  padding-right: 10px;
}

.calendar-info:before {
  @include font-awesome($fa-var-calendar);
  padding-right: 10px;
}

.time-info:before {
  @include font-awesome($fa-var-clock-o);
  padding-right: 10px;
}

/* approval code */

.approval-code {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #ccc;
}

/* buttons */

.orange-button, #content .richText .orange-button {
  display: inline-block;
  margin: 0;
  padding: 13px 30px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4rem;
  background: #ff6808;
  color: #fff;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.orange-button:hover, #content .richText .orange-button:hover {
  background: #ff6808;
  color: #fff;
}

.blue-button, #content .richText .blue-button {
  display: inline-block;
  margin: 0;
  padding: 13px 30px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4rem;
  background: #3A659F;
  color: #fff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.blue-button:hover, #content .richText .blue-button:hover {
  background: #3A659F;
  color: #fff;
}

/* login */

.login-box {
  padding: 30px 20px;
}

.main .richText.login-box-signup, .login-box-signup {
  background: $lightgray;
  padding: 0 30px 30px 30px;
}

.login-box-signup h3 {
  margin: 0;
  padding: 0;
}

#content .login-box-signup.richText p {
  padding: 20px 0 0 0;
}

/* boxes: video asset / image box */

.image-box {
  padding: 0;
}

.image-box > div {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* boxes: video asset / jwplayer */

.reference-video-jwplayer {
  @include align-center(medium);
  padding: 0 20px;
}

.reference-video-jwplayer .videoAsset h3 {
  padding-bottom: 20px;
}

.reference-video-jwplayer .videoAsset p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin: 0;
  padding: 10px 0;
}

.reference-video-jwplayer .videoAsset-video {
  display: block;
  position: relative;
  min-height: 20px;
  background: #fefefe;
  margin: 0;
}

.reference-video-jwplayer .videoAsset-description {
  padding: 10px 0 0 0;
}

.reference-video-jwplayer .videoAsset span.videoAsset-duration {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 5px 10px;
  font-size: 0.85rem;
  background: #000;
  color: #fff;
}

/* boxes: teaser white */

.teaser-white {
  height: 350px;
  padding: 20px 0;
  overflow: auto;
}

#content .teaser-white .richText h3, #content .teaser-white .richText h3 a {
  margin: 10px 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: #333;
}

#content .teaser-white .richText p, #content .teaser-white .richText a {
  color: #333;
}

/* home */

.home-feature {
  position: relative;
  clear: both;
  float: none;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #ececec;
  background-image: url(../../img/keyvisual.jpg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-feature > div {
  float: none;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 500px;
}

.home-feature .richText {
  position: relative;
  margin-top: 200px;
  padding: 30px 20px 10px 20px;
  background: #ececec;
}

.home-feature .richText:before {
  display: block;
  position: absolute;
  top: -80px;
  right: 0px;
  width: 260px;
  height: 150px;
  content: " ";
  background-image: url(../../img/stoerer.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999;
}

.home-feature .richText h1, #content .home-feature .richText h1,
.home-feature .richText h2, #content .home-feature .richText h2,
.home-feature .richText h3, #content .home-feature .richText h3,
.home-feature .richText p, #content .home-feature .richText p {
  color: $darkred;
}

.home-feature .richText h1, #content .home-feature .richText h1 {
  position: relative;
  margin: 0;
  padding: 30px 0;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 3.4rem;
}

.home-feature .richText h1:before {
  display: inline-block;
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  height: 10px;
  width: 240px;
  background-color: $darkred;
}

.reference-edetail-embeddable {
  margin: 20px 0;
  text-align: center;
}

.box-white > div {
  display: block;
  margin: 15px auto;
  padding: 20px;
  background: #fff;
}

.box-desktop > div {
  max-width: 1024px;
}

/* media queries */

@include respond-to(mobile) {

  .center-mobile {
    text-align: center;
  }

  /* spacing */

  .space-inside-top-mobile {
    padding-top: 30px;
  }

  .space-inside-left-mobile {
    padding-left: 30px;
  }

  .space-inside-right-mobile {
    padding-right: 30px;
  }

  .space-inside-bottom-mobile {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-mobile {
    border-top: 1px solid #c6c6c6;
  }

  .border-left-mobile {
    border-left: 1px solid #c6c6c6;
  }

  .border-right-mobile {
    border-right: 1px solid #c6c6c6;
  }

  .border-bottom-mobile {
    border-bottom: 1px solid #c6c6c6;
  }

  /* headlines */

  #content h1 {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  /* container */

  .container {
    right: 0;
    -webkit-transition: right .25s ease-out;
    -moz-transition: right .25s ease-out;
    -o-transition: right .25s ease-out;
    transition: right .25s ease-out;
  }

  .nav-is-open .container {
    display: block;
    right: -$navigation-drawer;
  }

  div#header {
    -webkit-transition: left .25s ease-out;
    -moz-transition: left .25s ease-out;
    -o-transition: left .25s ease-out;
    transition: left .25s ease-out;
  }

  .nav-is-open div#header {
    left: $navigation-drawer;
  }

  .reference-header-public > .inner > .component-content > div,
  .reference-header-hcp > .inner > .component-content > div {
    display: block;
  }

  .reference-header-public .mobile-menu,
  .reference-header-hcp .mobile-menu {
    display: flex;
    cursor: pointer;
    line-height: 62px;
    width: 80px;
    text-align: center;
  }

  .reference-header-public .mobile-menu > div.component-content,
  .reference-header-hcp .mobile-menu > div.component-content {
    display: inline-block;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .reference-header-public .mobile-menu > div.component-content > *,
  .reference-header-hcp .mobile-menu > div.component-content > * {
    display: none;
  }

  .reference-header-public .header-sidebar,
  .reference-header-hcp .header-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $navigation-drawer !important;
    min-height: 100vh;
    margin-left: -$navigation-drawer;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    background: #f2f2f2;
    -ms-overflow-style: none;
    overflow: auto;
  }

  .nav-is-open .reference-header-public .header-sidebar,
  .nav-is-open .reference-header-hcp .header-sidebar {
    margin-left: 0;
  }

  .reference-header-public .header-sidebar:after,
  .reference-header-hcp .header-sidebar:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    width: 10px;
    min-height: 100%;
    box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.2);
    content: "";
  }

  .reference-header-public .header-logo,
  .reference-header-hcp .header-logo {
    flex-grow: 1;
  }

  .reference-header-public .header-logo img,
  .reference-header-hcp .header-logo img {
    height: 100%;
  }

  .divider {
    padding-top: 15px;
  }

  .divider > div {
    height: 15px;
  }

  #header .searchBox {
    display: block;
    width: 100%;
  }

  #header .searchBox form {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    height: $header-height;
  }

  #header .searchBox .searchBox-title {
    display: none;
  }

  #header .searchBox form label {
    display: block;
    font-weight: 400;
  }

  #header .searchBox form input {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    margin: 0;
    padding: 4px 40px 4px 3px;
    line-height: 2rem;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 1rem;
    text-indent: 12px;
  }

  #header .searchBox form button {
    position: absolute;
    top: 12px;
    right: 15px;
    height: 40px;
    width: 40px;
    background: none;
    border: 0;
  }

  #header .searchBox form button {
    color: transparent;
    font-size: 0;
    cursor: pointer;
  }

  #header .searchBox form button:before {
    position: absolute;
    font-family: 'FontAwesome';
    content: "\f002";
    top: 8px;
    left: 8px;
    font-weight: 900;
    font-size: 1.2rem;
    display: inline-block;
    color: #000;
  }

  .header-sidebar .userBox {
    padding-bottom: 20px;
    font-family: 'Roboto', sans-serif;
  }

  .header-sidebar .userBox-header-text {
    display: none;
  }

  .header-sidebar .userBox  .logged-in .button {
    display: block;
    margin: 0;
    padding: 10px 4px 10px 44px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: #333;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .header-sidebar .userBox .logged-in .button:before {
    @include font-awesome($fa-var-sign-out);
    position: absolute;
    left: 18px;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  .header-sidebar .userBox .logged-in p {
    display: none;
  }

  .header-sidebar .userBox .logged-in a, .userBox .logged-out a {
    display: block;
    margin: 0;
    padding: 10px 4px 10px 44px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: #333;
  }

  .header-sidebar .userBox .logged-in a {
    border-top: 1px solid #dee2e6;
  }

  .header-sidebar .userBox .logged-in a:before {
    @include font-awesome($fa-var-user-circle);
    position: absolute;
    left: 18px;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  .header-sidebar .userBox .logged-out {
    visibility: collapse;
    font-size: 0;
    color: transparent;
  }

  .header-sidebar .userBox .logged-out a {
    visibility: visible;
  }

  .header-sidebar .userBox .logged-out a:before {
    @include font-awesome($fa-var-sign-in);
    position: absolute;
    left: 18px;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  .header-sidebar .userBox .logged-out .registration-text a {
    border-top: 1px solid #dee2e6;
  }

  .header-sidebar .userBox .logged-out .registration-text a:before {
    @include font-awesome($fa-var-user-circle-o);
    position: absolute;
    left: 18px;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  /* footer */

  div#footer .footer-info {
    text-align: center;
  }

  div#footer .footer-info .footer-info-hotline {
    text-align: center;
  }

  div#footer .footer-info .footer-info-hotline .richText-content {
    display: inline-block;
    width: auto !important;
    text-align: left;
  }

  div#footer .footer-links {
    text-align: center;
  }

  .reference-footer .userBox {
    display: none;
  }

  .reference-footer .contact .richText:first-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, .35);
  }

  /* login */

  .login-box {
    text-align: center;
  }

  /* home */


}

@include respond-to(tablet) {

  /* spacing */

  .space-inside-top-tablet {
    padding-top: 30px;
  }

  .space-inside-left-tablet {
    padding-left: 30px;
  }

  .space-inside-right-tablet {
    padding-right: 30px;
  }

  .space-inside-bottom-tablet {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-tablet {
    border-top: 1px solid #c6c6c6;
  }

  .border-left-tablet {
    border-left: 1px solid #c6c6c6;
  }

  .border-right-tablet {
    border-right: 1px solid #c6c6c6;
  }

  .border-bottom-tablet {
    border-bottom: 1px solid #c6c6c6;
  }

  /* home */

  .home-feature {
    background-size: contain;
  }

  .home-feature .richText {
    margin-top: 0;
    padding: 60px 15px;
  }

  .home-feature .richText:before {
    top: 20px;
    right: -150px;
  }

  /* snippets: service boxes */

  .reference-service-boxes .component-content > div.image {
    padding: 30px;
  }

  .reference-service-boxes .image .component-content {
    font-size: 0.85rem;
    line-height: 1.2rem;
  }

  /* layout: footer */

  #footer .footer-links ul li {
    display: inline-block;
    padding-right: 20px;
  }

  .reference-footer .contact .richText:first-of-type {
    border-bottom: 0;
    border-right: 1px solid rgba(255, 255, 255, .35);
  }

}

@include respond-to(desktop) {

  /* spacing */

  .space-inside-top-desktop {
    padding-top: 30px;
  }

  .space-inside-left-desktop {
    padding-left: 30px;
  }

  .space-inside-right-desktop {
    padding-right: 30px;
  }

  .space-inside-bottom-desktop {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-desktop {
    border-top: 1px solid #c6c6c6;
  }

  .border-left-desktop {
    border-left: 1px solid #c6c6c6;
  }

  .border-right-desktop {
    border-right: 1px solid #c6c6c6;
  }

  .border-bottom-desktop {
    border-bottom: 1px solid #c6c6c6;
  }

  /* mobile menu */

  .mobile-menu {
    display: none;
  }

  .reference-header-public .header-sidebar,
  .reference-header-hcp .header-sidebar {
    flex-grow: 1;
  }

  .reference-header-public .header-sidebar > div,
  .reference-header-hcp .header-sidebar > div {
    float: right;
  }

  #header .searchBox {
    display: none;
  }

  .only-mobile {
    display: none !important;
  }

  /* user box */

  .header-sidebar .userBox {
    display: none;
  }

  .reference-footer .userBox-header-text {
    display: none;
  }

  .reference-footer .userBox .logged-in form {
    display: inline-block;
  }

  .reference-footer .userBox .logged-in .button {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 10px 24px;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.6rem;
    color: #fff;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .reference-footer .userBox .logged-in .button:before {
    @include font-awesome($fa-var-sign-out);
    position: absolute;
    display: inline-block;
    left: 0;
    font-weight: 900;
    font-size: 0.9rem;
  }

  .reference-footer .userBox .logged-in p {
    display: none;
  }

  .reference-footer .userBox .logged-in a, .userBox .logged-out a {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 10px 24px;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.6rem;
    color: #fff;
  }

  .reference-footer .userBox .logged-in a:before {
    @include font-awesome($fa-var-user-circle);
    position: absolute;
    left: 0;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  .reference-footer .userBox .logged-out {
    visibility: collapse;
    font-size: 0;
    color: transparent;
  }

  .reference-footer .userBox .logged-out a {
    visibility: visible;
  }

  .reference-footer .userBox .logged-out a:before {
    @include font-awesome($fa-var-sign-in);
    position: absolute;
    left: 0;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  .reference-footer .userBox .logged-out .registration-text a:before {
    @include font-awesome($fa-var-user-circle-o);
    position: absolute;
    left: 0;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  .reference-footer .contact .richText:first-of-type {
    border-bottom: 0;
    border-right: 1px solid rgba(255, 255, 255, .35);
  }

  /* login */

  .login-box {
    @include align-center(medium);
  }

  .gigyaraas {
    float: left;
    clear: none;
    max-width: 60%;
    margin: 0;
  }

  #content .richText.login-box-signup, .login-box-signup {
    float: right;
    clear: none;
    width: 35%;
    margin-left: 0;
    margin-right: 0;
    padding: 30px;
  }

  /* layout: content */

  .main .title {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .title:first-of-type {
    padding-top: 30px;
  }

  .main .image {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .richText {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .table {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .searchBox {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .searchResults {
    @include align-center(medium);
    @include desktop-padding();
  }

  /* layout: content / top bar box */

  .top-bar-box-right {
    float: right;
    max-width: 680px;
    margin: 10px;
    padding: 20px;
  }

  .top-bar-box > div:before {
    top: 20px;
  }

  /* layout: footer */

  .reference-footer .component-content {
    text-align: left;
  }

  .reference-footer .richText ul li {
    display: inline-block;
    padding-right: 15px;
  }

  /* home */

  .home-feature {
    background-size: contain;
  }

  .home-feature .richText {
    margin-top: 0;
    padding: 90px 15px 60px 15px;
  }

  .home-feature .richText:before {
    top: 80px;
    right: -200px;
  }

}

@include respond-to(large-desktop) {

  /* home */



}