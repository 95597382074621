.carousel {
  padding: 0;
}

.carousel > .component-content {
  position: relative;
  padding: 0;
  border: none;
}

.carousel-slides {
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.carousel-slide {
  clear: both;
  position: absolute;
  width: 100%;
}

.carousel-slide > * {
  padding: 0;
}

.carousel-slide .no-content,
.carousel-slide .no-image {
  text-align: left;
}

.carousel-slide.is-active {
  display: block;
  position: relative;
}

.carousel-slide .image-and-content {
  overflow: hidden;
}

.carousel-slide .no-content .carousel-cover {
  float: none;
  max-width: 100%;
}

.carousel-slide .no-image .carousel-content {
  float: none;
  max-width: 100%;
}

.carousel-cover {
  max-width: 50%;
  height: auto;
  float: right;
}

@media only screen and (max-width: 767px) {
  .carousel-cover {
    max-width: 100%;
    float: none;
    margin: auto;
    display: block;
  }
}

.carousel-content {
  max-width: 48%;
  float: left;
  width: 100%;
  text-align: left;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

@media only screen and (max-width: 767px) {
  .carousel-content {
    max-width: 100%;
    float: none;
  }
}

.carousel-nav {
  position: absolute;
  z-index: 999;
  margin: 0;
  padding: 10px 0;
  bottom: 5px;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.carousel-nav li {
  display: inline-block;
  margin: 0 1px;
  padding: 0;
}

.carousel-nav li a {
  text-indent: -9999px;
  overflow: hidden;
  background: #ccccc9;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.carousel-nav li.is-active a {
  background: #fff;
}

.carousel-nav-prev, .carousel-nav-next {
  display: none;
}

.carousel-nav-prev a, .carousel-nav-next a {
  display: none;
}

.no-js .carousel-slide {
  position: static;
}

.no-js .carousel-slide:first-child {
  margin-top: 0;
  border-top: none;
}

.no-js .carousel-nav li {
  display: none;
}