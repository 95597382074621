@import '../core/mixins';

.accordion-container {
  padding: 20px 0;
  margin: 0;
  list-style: none;
}

.accordion-slide {
  border-bottom: 1px solid $lightgray;
}

.accordion-head {
  display: block;
  padding: 15px 0;
  color: $darkgray;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.2rem;
  background: transparent;
}

.accordion-title {
  float: none;
  margin: 0;
  padding: 0 20px 0 0;
  display: inline-block;
}

#content h3.accordion-title {
  padding: 0;
}

.accordion-title a {
  color: $darkgray;
}

.accordion-content {
  padding: 10px 0;
}

.accordion-content .richText {
  padding: 0;
}

.accordion-content-wrapper {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.mod-js .accordion-content-wrapper {
  position: relative;
  height: 0;
  top: -999999px;
  left: -999999px;
  overflow: hidden;
  box-sizing: border-box;
  visibility: hidden;
}

.mod-js .accordion-head {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  text-indent: 0;
}

.mod-js li.accordion-slide:not(.is-active) .accordion-head:hover {
  text-indent: 10px;
  background-color: #f1f1f1;
}

.mod-js .accordion-head:after {
  position: absolute !important;
  top: 50%;
  margin-top: -10px;
  right: 0;
  content: "\f054";
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  position: relative;
  font-weight: normal;
  font-size: 20px;
  transition: all 0.3s ease;
}

.mod-js li.accordion-slide:not(.is-active) .accordion-head:hover:after {
  right: 15px;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover,
.mod-js .accordion-slide.is-active > .accordion-head {
  color: $darkgray;
  cursor: pointer;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover:after,
.mod-js .accordion-slide.is-active > .accordion-head:after {
  right: 15px;
  content: "\f078";
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  position: relative;
  font-weight: normal;
}

.mod-js .accordion-slide .accordion-head:hover {
  color: $darkgray;
}

.mod-js .accordion-slide.is-active > .accordion-head {
  color: black;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover {
  background-color: transparent;
}

.mod-js .accordion-slide.is-active > .accordion-content-wrapper {
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  visibility: visible;
}

.mod-js .accordion-slide.is-active:last-child > .accordion-content-wrapper {
  border-bottom: none;
}
